/* eslint-disable */
const {
	initReactI18next
} = require("react-i18next")
module.exports = {
	debug: false,
	defaultNS: "common",
	fallbackNS: "common",
	i18n: {
		defaultLocale: "en",
		locales: ["de", "en"]
	},
	initImmediate: false,
	interpolation: {
		escapeValue: false
	},
	ns: ["common", "narkuma", "narkuma-school"],
	react: {
		bindI18n: "languageChanged",
		bindI18nStore: "",
		transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
		transSupportBasicHtmlNodes: true,
		useSuspense: true
	},
	serializeConfig: false,
	use: [initReactI18next]
}