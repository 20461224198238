import { Modal } from "@redq/reuse-modal";
import "@redq/reuse-modal/es/index.css";
import "common/assets/css/flaticon.css";
import "../containers/CryptoModern/CountDown/timer.css";
import "common/assets/css/icon-example-page.css";
// swiper bundle styles
import "swiper/css/bundle";
import "common/assets/css/react-slick.css";
import "common/assets/css/rc-collapse.css";
import "rc-collapse/assets/index.css";
import getConfig from "next/config"
import { appWithTranslation } from "next-i18next"
import nextI18nNextConfig from "../../next-i18next.config"
import { createInstance, MatomoProvider, useMatomo } from "@datapunt/matomo-tracker-react";
import { useEffect } from "react";
import { useRouter } from "next/router";
const {
	matomoUrl,
	matomoSiteId
} = getConfig().publicRuntimeConfig;
const matomoSettings = {
	siteId: matomoSiteId,
	srcUrl: `https://${ matomoUrl }/matomo.js`,
	trackerUrl: `https://${ matomoUrl }/matomo.php`,
	heartBeat: {
		active: true,
		seconds: 10
	},
	linkTracking: true,
	urlBase: matomoUrl
}
const instance = createInstance(matomoSettings);
const App =({
	Component,
	pageProps
}) => {
	return (
		<MatomoProvider value={ instance }>
			<Content>
				<Modal>
					<Component { ...pageProps } />
				</Modal>
			</Content>
		</MatomoProvider>
	);
}
const Content = ({children}) => {
	const {
		trackPageView,
		trackEvent,
		enableLinkTracking
	} = useMatomo()
	const {
		events,
	} = useRouter()
	enableLinkTracking()
	useEffect(() => {
		trackPageView()
		trackEvent({
			category: "narkuma-landingpage",
			action: "landing",
			name: "narkuma",
			value: "main"
		});
		events.on("routeChangeStart", () => {
			trackPageView()
		})
	}, [])
	return (
		<Modal>
			{children}
		</Modal>
	)
}
export default appWithTranslation(App, nextI18nNextConfig)